import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Head from "../components/head"

const NotFound = () => {
  return (
    <Layout>
      <Head title="404" />
      <h1>This not the the page you are looking for</h1>
      <p>
        <Link t="/">
          If all else fails go back to the begining and start over
        </Link>
      </p>
    </Layout>
  )
}

export default NotFound
